.project-board {
    @include mediaQueries(project-grid);
    z-index: 1;
    margin: 2rem 0rem 0rem 0rem;
}

.project-page-header {
  margin: 1rem 2rem 0rem 2rem;
}

.project-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px; 
    min-height: 24rem;
  }
  
  .project-card-inner {
    @include flex(column, space-between);
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .project-card:hover .project-card-inner {
    transform: rotateY(180deg);
    cursor: pointer;
  }
  
  .project-card-front, .project-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  .project-card-front {
    background-color: $dark-grey;
    color: $light;
  }
  
  .project-card-back {
    background-color: $dark-grey;
    color: $light;
    transform: rotateY(180deg);

    h3 {
        font-size: 2rem;
        margin: 1rem;
    }

    p {
        margin: 1rem 3rem 1rem 3rem;
    }

    span {
      color: lightgray;
    }
  }
  
  .project-header {
      border-bottom: 1px solid $light;

    img {
        height: 2.5rem;
        width: 2.5rem;
        color: $light;
    }
  }

  .accomplishments {
    font-weight: bold;
    color: lightgray;
  }

  .project-text {
    border-top: 2px solid $dark; 
    padding-top: 1rem;
  }

  .project-links {
    @include flex(row, center);

    a {
      margin: 0rem 1rem 0rem 1rem;
    }
  }
