/* ==========================================================================
Abstracts - Typography
========================================================================== */

/* === Font Import === */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Parisienne&display=swap');

/* === Defaults === */
$ff-primary: 'Open Sans', sans-serif;
$ff-secondary: 'Raleway', sans-serif;

/* === Font Size === */
$font-size-heading: clamp(1rem, 3vw, 1.5rem);
$font-size-subheading: clamp(1rem, 1vw, 1.5rem);
$font-size-paragraph: clamp(1rem, 1vw, 2.5rem);

