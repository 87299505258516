.contact {
    @include flex(column, center);
    background-color: $dark;
    color: $light;

    h2 {
        @include mediaQueries(bio);
        margin: 1.5rem 6rem 0rem 6rem;
        line-height: initial;
    }

    a {
        z-index: 5;
        color: $light-teal;
        margin-bottom: 20px;

        &:hover {
            color: rgb(250, 250, 250);
        }
    
    }

    p {
        text-align: center;
    }
}

.resume {
    margin: 1rem 1rem 0rem 1rem;
    color: $light;
    height: 2rem; 
    width: 10rem; 
    overflow-y: hidden;
    z-index: 5;
  }