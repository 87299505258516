footer {
    @include flex(column, center);
    @include mediaQueries(footer);
    color: $light;
    background-color: $dark;
    padding: .5rem;
    width: 100%;
    bottom: 0;
    border-top: 1px solid gray;

    p {
        font-size: .8rem;
    }

    a {
        z-index: 5;
        color: $light-teal;
        font-size: .8rem;

        &:hover {
            color: rgb(250, 250, 250);
        }
    
    }
}
