.nav {
    @include flex(row, space-between);
    @include mediaQueries(nav);
    position: sticky;
    background: none;
    top: 0;
    height: 4rem;
    z-index: 5;
    backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(100, 100, 100, 0.5);

  }
}

.navbtn {
    margin: 1rem;
    color: $light;
    border: none;
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $light;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      
      &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
  }
    .active {
      background: $teal;
      color: $light;
    }

  .nav-header {
    @include flex(row, center);
    @include mediaQueries(header);
    margin: 0rem;
    color: $light;
    font-size: $font-size-paragraph;

    img {
      width: 32rem;
    }
  }

 