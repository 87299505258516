/* ==========================================================================
Abstracts - Colors
========================================================================== */

/* === Palette === */
$teal: rgba(79, 221, 234, 0.54);
$light-teal: rgba(156, 236, 244, 0.92);
$peach: rgba(247, 213, 184, 0.892);

/* === Neutrals === */
$light: #ffffff;
$dark: #000000;
$background: black;
$dark-grey: rgba(72, 72, 72, 0.529);
$light-grey: rgb(228, 228, 228);
$box-shadow: 0 9px 15px -9px #000;
