/* ==========================================================================
Base - Media Queries
========================================================================== */

/* ===  Trigger === */
@mixin mediaQueries($property) {
    @include mobile($property);
    @include tablet($property);
    @include desktop($property);
  }
  
  /* === Mobile === */
  @mixin mobile($property) {
    @media only screen and (min-width: 250px) {
      @content;
      transition: 100ms;
      @if $property==project-grid {
        @include project-grid(mobile);
      } @else if $property==tech-grid {
        @include tech-grid(mobile);
      } @else if $property==nav {
        @include flex(row, center);
      } @else if $property==header {
        margin: 2.5rem 0rem -1rem 0rem;
      } @else if $property==tech {
        @include flex(column, center);
        z-index: 5;
      } @else if $property==card {
        @include flex(column, center);
        height: 300px;
        width: 380px;
      } @else if $property==logo {
        width: 25rem;
      } @else if $property==mountain {
        margin-top: 20px;
      } @else if $property==bio {
        font-size: 35px;
        font-weight: bold;
      } @else if $property==footer {
        text-align: center;
      } @else if $property==hover-text {
        width: 80%;
        position: fixed;
        bottom: 320px;
        right: 20px;
      }
    }
  }
  
  /* === Tablet === */
  @mixin tablet($property) {
    @media only screen and (min-width: 768px) {
      @content;
      transition: 100ms;
      @if $property==project-grid {
        @include project-grid(tablet);
      } @else if $property==tech-grid {
        @include tech-grid(tablet);
      } @else if $property==nav {
        @include flex(row, space-between);
      } @else if $property==header {
        margin: 0rem;
      } @else if $property==tech {
        @include flex(column, center);
        z-index: 5;
      } @else if $property==card {
        @include flex(column, center);
        height: 300px;
        width: 400px;
      } @else if $property==logo {
        width: 25rem;
      } @else if $property==mountain {
        height: 125vh;
      } @else if $property==bio {
        font-size: 35px;
        font-weight: bold;
      } @else if $property==hover-text {
        width: 50%;
        position: fixed;
        bottom: 30px;
        right: 30px;
      }
    }
  }
  
  /* === Desktop === */
  @mixin desktop($property) {
    @media only screen and (min-width: 1024px) {
      @content;
      transition: 100ms;
      @if $property==project-grid {
        @include project-grid(desktop);
      } @else if $property==tech-grid {
        @include tech-grid(desktop);
      } @else if $property==nav {
        @include flex(row, space-between);
      } @else if $property==header {
        margin: 0rem;
      } @else if $property==tech {
        @include flex(row, center);
        z-index: 5;
      } @else if $property==card {
        @include flex(column, center);
        height: 350px;
        width: 250px;
      } @else if $property==logo {
        width: 35rem;
      } @else if $property==mountain {
        height: 150vh;
      } @else if $property==bio {
        font-size: 50px;
        font-weight: bold;
      } @else if $property==hover-text {
        width: 30%;
        position: fixed;
        bottom: 30px;
        right: 30px;
      }
    }
  }

