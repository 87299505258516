.home {
  position: relative;
}

canvas {
  z-index: 2 !important;
  position: absolute !important;
}

.logo {
  @include mediaQueries(logo);
  z-index: 2;
}

.info {
  @include flex(column, center, flex-end);
}

.icon {
  z-index: 5;

  &:hover {
    transform: scale(1.1)
  }
}

.hover-text {
  @include mediaQueries(hover-text);
  font-size: 16px;
  padding: 10px;
  background-color: $dark-grey;
  color: white;
  text-align: left;
  z-index: 5;
  border-radius: 5px;
}

.mountain {
  @include mediaQueries(mountain);
  z-index: 1;
  min-width: 100vw;
}

.about {
  @include flex(column, center);
  @include mediaQueries(about);
  background-color: $dark;
  color: $light;
  z-index: 5;
}

.bio-details {
  z-index: 5;
  text-align: center;
  margin: 50px 60px 0px 60px;
}

.bio-text {
  @include mediaQueries(bio);
}

.bio-btns {
  @include flex(row, center);
  margin: 10px;
  z-index: 5;

  button {
    margin: 20px;
  }
}

.tech-container {
  @include mediaQueries(tech-grid);
  z-index: 5;
  width: 100vw;
}

.tech-content {
  z-index: 5;
  color: $light;
  padding: .5rem;
  margin: .5rem;
  text-align: left;
  font-family: $ff-secondary;
  font-size: $font-size-paragraph;
}

.tech-header-container {
  @include flex(column, center);
  z-index: 5;
  border-bottom: 1px solid $light-grey;
  margin: 0rem 0rem .5rem 0rem;
  color: $light-grey;
}

.tech-header-sub-text {
  color: $light-teal;
  padding: 0rem .5rem 0rem .5rem;
}

.card {
  @include mediaQueries(card);
  background: $dark;
  text-align: center;
  margin: 0.8rem;    
  --borderWidth: 2px;
  position: relative;
  border-radius: var(--borderWidth);
}
.card:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.emoji {
  font-size: 45px;
  animation-name: wave-animation; 
  animation-duration: 2.5s;       
  animation-iteration-count: 2; 
  transform-origin: 70% 70%;  
  display: inline-block;
  @keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}
}




  