/* ==========================================================================
  Elements - Landmarks
========================================================================== */

  html {
    background: $background;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }

  body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  
  header {
    @include flex(row, between);
  }
  
  main {
    @include flex(column, center);
    width: 100%;
  }
  
  article {
    @include flex(column, around);
  }
  
  button {
    z-index: 5;
    transition: 500ms;
    border: $light-grey 1px solid;
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    font-family: $ff-secondary;
    color: $teal;
    &:hover {
      transition: 500ms;
      background: $dark-grey;
      border: $light-teal 1.5px solid;
      text-shadow: 0 0 1.5px $light;
      color: $light;
    }
  }
  
  a {
    color: $dark;
    font-family: $ff-secondary;
  }

  span {
    color: $dark;
    font-family: $ff-secondary;
  }

  h1 {
    font-family: $ff-primary;
    font-size: $font-size-heading;
    color: $light;
    text-align: center;
  }

  h2 {
    font-family: $ff-primary;
    font-size: $font-size-heading;
    padding: 0;
    text-align: center;
  }

  h3 {
    font-family: $ff-primary;
    font-size: $font-size-subheading;
    text-align: center;
  }

  p {
    font-family: $ff-secondary;
    font-size: $font-size-paragraph;
  }

  